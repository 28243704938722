<template>
  <m-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Create Exam' : 'পরীক্ষা তৈরী করুন'"
      class="mt-5"
    >
    </page-title>

    <lazy-load :item="!loadingExam">
      <exam-create-form
        :course-id="courseId"
        :exam="sourceExam"
        @done="onDone"
      ></exam-create-form>
    </lazy-load>
  </m-container>
</template>

<script>
import ExamCreateForm from "#ecf/exam/components/ExamCreateForm";

export default {
  name: "ExamCreate",
  components: {
    ExamCreateForm
  },
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    saving: false,
    sourceExam: {},
    loadingExam: false
  }),
  inject: ["$courseOwnerId", "$courseId"],
  computed: {},
  async created() {
    if (this.$route.query.duplicate_from) {
      try {
        this.loadingExam = true;
        const sourceExam = await this.$store.dispatch("exam/get", {
          id: this.$route.query.duplicate_from,
          course_id: this.$courseId(),
          owner_id: this.$courseOwnerId()
        });
        delete sourceExam.id;
        this.sourceExam = sourceExam;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingExam = false;
      }
    }
  },
  methods: {
    async onDone() {
      await this.$router.replace({ name: "exams" });
    },
    async cancel() {
      this.$router.back();
    }
  }
};
</script>
